import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styling/para-slider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const ImageSlider = () => {
  const CustomPrevArrow = ({ onClick }) => (
    <div onClick={onClick} className="slider-arrow-left">
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );

  const CustomNextArrow = ({ onClick }) => (
    <div onClick={onClick} className="slider-arrow-right">
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    pauseOnHover: false,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const slides = [
    {
      image: "/images/home-page/testomonial_man.webp",
      text: "My job is to collect plastic\nbottles from the beach and\nlagoons and hand them over to\na Eco Spindles recycling\ncompany. Several people like\nme do this job.",
      text2: "- Vijey, Aluthgama",
    },
    {
      image: "/images/home-page/testomonial_women.webp",
      text: "We are very happy\ndoing this job. That income has\nenabled our children to go to\nschool, go for classes, eat and\ndress well. We thank this\ninstitution for their service.",
      text2: "- P.D. Chandani, Anuradhapura",
    },
  ];

  return (
    <div>
      <center>
        <div className="para-slider-width">
          <Slider {...settings}>
            {slides.map((slide, idx) => (
              <div key={idx}>
                <div className="pc">
                  <table className="pc-table">
                    <tbody>
                      <tr>
                        <td className="pc-td-first-line">
                          <img
                            className="pc-slide-image"
                            src={slide.image}
                            alt={`slide ${idx}`}
                          />
                        </td>
                        {/* <td className="pc-td-second-line">
                          <div className="para-slider-bac">
                            {slide.text.split("\n").map((line, lineIdx) => (
                              <p key={lineIdx} className="text-para">
                                {line}
                              </p>
                            ))}
                            <h2 className="text-para-2">{slide.text2}</h2>
                          </div>
                        </td> */}

                        <td className="pc-td-second-line">
                          <div className="para-slider-bac">
                            {slide.text.split("\n").map((line, lineIdx) => (
                              <React.Fragment key={lineIdx}>
                                <p className="text-para">{line}</p>
                                {/* If you want to optimize further, you can remove the key from the paragraph */}
                                {/* <p className="text-para">{line}</p> */}
                              </React.Fragment>
                            ))}
                            <h2 className="text-para-2">{slide.text2}</h2>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mobile">
                  <table className="mobile-class-table">
                    <tbody>
                      <tr>
                        <td className="mobile-class-td-first-line">
                          <img
                            className="mobile-class-img"
                            src={slide.image}
                            alt={`slide ${idx}`}
                          />
                        </td>
                      </tr>
                      <tr>
                        {/* <td className="mobile-class-td-second-line">
                          <div className="para-slider-bac">
                            {slide.text.split("\n").map((line, lineIdx) => (
                              <p key={lineIdx} className="text-para">
                                {line}
                              </p>
                            ))}
                            <h2 className="text-para-2">{slide.text2}</h2>
                          </div>
                        </td> */}

                        <td className="mobile-class-td-second-line">
                          <div className="para-slider-bac">
                            {slide.text.split("\n").map((line, lineIdx) => (
                              <React.Fragment key={lineIdx}>
                                <p className="text-para">{line}</p>
                                {/* If you want to optimize further, you can remove the key from the paragraph */}
                                {/* <p className="text-para">{line}</p> */}
                              </React.Fragment>
                            ))}
                            <h2 className="text-para-2">{slide.text2}</h2>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </Slider>
          <br />
        </div>
      </center>
    </div>
  );
};

export default ImageSlider;
