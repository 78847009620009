import React, { useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import "../../styling/templates.css";

function App() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState("");

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
    setCaptchaError(""); // Clear the error if captcha is successfully verified
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!captchaToken) {
      setCaptchaError("Please complete the hCaptcha verification.");
      return;
    }

    const formData = new FormData(event.target);

    // Append static fields
    formData.append(
      "subject",
      formData.get("subject") || "ECO Spindles - Products Email Form"
    );
    formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");
    formData.append("h-captcha-response", captchaToken);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      const responseData = await res.json();

      if (res.ok && responseData.success) {
        setSuccessMessage("Form submitted successfully!");
        setErrorMessage("");
        window.location.href = formData.get("redirect");
      } else {
        setErrorMessage(
          responseData.message || "Form submission failed. Please try again."
        );
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      setSuccessMessage("");
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit} id="ecospindleshomeemailform">
        <input
          type="hidden"
          name="redirect"
          value="https://web3forms.com/success"
        />
        <div className="home-email-part">
          <input
            type="email"
            name="Consumer Email:"
            required
            className="inputStyle2"
            placeholder="Your Email here"
            style={{ marginRight: "10px", fontSize: "15px" }}
          />

          <input
            type="hidden"
            name="ccemail"
            value="shenali@3cs.solutions; priyankara@3cs.solutions; info@3cs.lk"
          />

          <input type="checkbox" name="botcheck" style={{ display: "none" }} />

          <input name="from_name" type="hidden" value="ECO Spindles" />
          <input
            name="subject"
            type="hidden"
            value="ECO Spindles - Products Email Form"
          />

          <button
            type="submit"
            className="btn submitButton-3 btn-lg"
            id="button"
            style={{ marginTop: "-30px" }}
          >
            Send
          </button>
        </div>

        {/* Add the hCaptcha component */}
        <HCaptcha
          sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
          reCaptchaCompat={false}
          onVerify={handleCaptchaVerify}
        />

        {/* Show error messages */}
        <div style={{ height: "30px", paddingTop: "0.5px" }}>
          {captchaError || errorMessage ? (
            <div className="error-message-2">
              {captchaError || errorMessage}
            </div>
          ) : successMessage ? (
            <div className="success-message-2">{successMessage}</div>
          ) : null}
        </div>

        <br />
        <br />
      </form>
    </div>
  );
}

export default App;
