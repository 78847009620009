import React, { useEffect } from "react";
import Home from "../templates/home/home";
import { Helmet } from "react-helmet";
import {
  MetaTitle,
  MetaDescription,
  MetaKeywords,
  MetaOGTitle,
  MetaOGDescription,
  MetaURL,
  MetaImage,
} from "../components/meta-tags";

export default function Index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = MetaTitle();
  const description = MetaDescription();
  const metaKeywords = MetaKeywords();
  const metaOGTitle = MetaOGTitle();
  const metaOGDesc = MetaOGDescription();
  const metaURL = MetaURL();
  const metaImage = MetaImage();

  const videoURL = "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/home/eco_spindles_sri_lanka.webm";


  return (
    <html lang="en">
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta name="keywords" content={metaKeywords} />
          <link rel="canonical" href={metaURL} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaOGTitle} />
          <meta property="og:description" content={metaOGDesc} />
          <meta property="og:url" content={metaURL} />
          <meta property="og:image" content={metaImage} />
          <link fetchPriority="high" rel="preload" href="/videos/home-video.webm" as="video" type="video/webm"/>
          <link rel="preload" href={videoURL} as="video" type="video/webm" />
          <link rel="preload" href="https://cse.google.com/cse.js?cx=d09ac2e3685724410" as="script" />
        </Helmet>

        <div>
          <Home />
        </div>
      </div>
    </html>
  );
}
