import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const LogoSlider = () => {
  const CustomPrevArrow = ({ onClick }) => (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        left: -20,
        zIndex: 1,
        color: "black",
        cursor: "pointer",
        fontSize: "25px",
        lineHeight: "25px",
      }}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );

  const CustomNextArrow = ({ onClick }) => (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        right: -20,
        zIndex: 1,
        color: "black",
        cursor: "pointer",
        fontSize: "25px",
        lineHeight: "25px",
      }}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 765, // tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const images = [
    "/images/logos/Triscel-Logo.jpg",
    "/images/logos/2.-BPTL-Logo-1.jpg",
    "/images/logos/1200px-Asics_Logo.svg.jpg",
    "/images/logos/calico_logo.jpg",
    "/images/logos/Decathlon_Logo-2.jpg",
    "/images/logos/Fantasia-Narrow-Fabrics-PNGG.jpg",
    "/images/logos/Hayleys-Fabric-logo_Colour-CMYK.jpg",
    "/images/logos/Hidaramani-Logo-HIR_MasterLogo_Pos_RGB.jpg",
    "/images/logos/MAS-Holdings.jpg",
    "/images/logos/MATRIX-LOGO-VECTOR2-1.jpg",
    "/images/logos/Naturub-Logo-1.jpg",
    "/images/logos/NorthSails_Bullet_RGB.jpg",
    "/images/logos/OW_Final_Logo-3_2000x350.jpg",
    "/images/logos/Picture1.jpg",
    "/images/logos/Teejay-Logo.webp",
    "/images/logos/Hidaramani-Logo-HIR_MasterLogo_Pos_RGB.jpg",
  ];

  return (
    <div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="container">
            <center>
              <img src={image} alt="logo" className="logo-image" />
            </center>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LogoSlider;
